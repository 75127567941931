.asideLeftQR {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.asideLeft_container {
    display: flex;
    flex-direction: column;
    padding: 0.5em;
}
.asideLeftLogo {
    border-radius: 10px;
    width: 40%;
}
.codigoqr {
    width: 60%;
    margin: 0.7em auto;
}
.playstore {
    width: 80%;
    margin: 0em auto;
}
.asideLeft h1{
    font-size: 1.5em;
    text-align: center;
 }
.asideLeft h2{
    font-size: 1.3em;
    text-align: center;
 }
.asideLeft h3{
   font-size: 1em;
}
.separator {
    border: none;
    border-top: 2px solid #ccc;
    margin: 1em 0em 2em 0em;
}