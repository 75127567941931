.form_match_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: var(--card);
    color: white;
    font-size: 16px;
    padding: 1em;
    width: 70%;
    margin: 1em auto;
}
.form_match_row {
    display: flex;  
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.form_match_row *{
    margin: 15px;
}
.btn_countPoints{
    padding: 0.4em 0.2em;
    border: solid white 3px;
    border-radius: 7px;
    font-weight: bold;
    background-color:  black;
    color: var(--primary);
    margin-top: 5%;
    width: 50%;
    text-align: center;
}
.btn_countPoints:hover{
    border: solid black 3px;
    border-radius: 7px;
    font-weight: bold;
    background-color:  var(--primary);
    color: black;
}
.form_match_row input {
    text-align: center;
}
label {
    text-align: left;
}
.form_match_players{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}
.input_points{
    width: 20%;
}
.input_pointsBaja{
    width: 20%;
}
.form_match_totalPoints {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.form_match_totalPoints h2 {
    margin: 1em;
    color: var(--primary);
}

@media (max-width: 600px) {
    .form_match_container {
        font-size: 14px;
        font-weight: bold;
        width: 100%;
        padding: 0em 0em;
    }
    .btn_submit_match{
        border: solid black 2px;
        border-radius: 5px;
        font-size: 14px;
    }
    .btn_countPoints{
        border: solid var(--primary) 2px;
        border-radius: 5px;
        font-size: 14px;
    }

}