.tournament_item {
    display:flex;
    justify-content: center;
    align-items: center;
    background: var(--background);
    color: white;
    border: solid black 1px;
    border-radius: 5px;
    margin-bottom: var(--margin);
    padding: 5px 0px;
}
.tournament_item:hover {
    background: var(--hover);
    border-radius: 7px;
    margin-bottom: 12px;
}
.tournament_item_box {
    width: 100%;
}
.tournament_item_container{
    display:grid;
    grid-template-columns: 1fr 3fr;
    align-items: center;
    padding: var(----padding);
    width: 100%;
}
.tournament_item_container *{
    text-align: center;
}
.tournament_item_center{
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
    align-items: center;
}
.tournament_item_center div{
    width: 100%;
}
.tournament_item_right{
    display: grid;
    grid-template-columns: 2fr 1fr;
}


.tournament_item_container h1 {
    font-size: 5em;
    text-align: center;
    font-family:Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
}

.tournament_item_container img {
    height: 10vh;
    margin: auto;
    padding: 0.2em;
    border-radius: 10%;
    box-shadow: 0 1px 5px 0 var(--text), 0 1px 3px 0 rgba(0, 0, 0, 0.1);
}

.tournament_item_container h3{
    font-size: 1.3em;
}
.tournament_item_date {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
}
.tournament_item_date h5 {
    margin: 0.7em 0.7em;
}
.num-teams-item{
    display: grid;
    grid-template-columns: 1fr 1fr;;
    align-items: center;
}
.num-teams-item *{
    padding: 0px 0.1em;
}
.tournament_item_join{
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
}
.tournament_item_join button {
    padding: 3px 20px;
    width: 150px;
}
.nombreMapa {
    font-size: 14px;
}
@media (max-width: 600px) {
    .tournament_item{
        background: var(--hover);
        display: grid;
        flex-direction: column;
        align-items: center;
        padding: var(--padding-small); 
    }
    .tournament_item_container{
        display: grid;
        grid-template-columns: 1fr 3fr;
    }
    .tournament_item_center{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .tournament_item_container h1 {
        font-size: 3em;
    }
    .tournament_item_container h3 {
        font-size: 1em;
    }
    .tournament_item_container h4 {
        font-size: 0.9em;
    }
    .tournament_item_container img {
        height: 7vh;
        margin: auto;
    }
    .num-teams-item{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .tournament_item_join button {
        margin: 0.1em 0em;
        padding: 2px 0px;
        width: 80%;
    }
    .nombreMapa {
        font-size: 12px;
    }
}