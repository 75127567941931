
.player_detail_section {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: var(--hover);
    color: white;
    margin-top: var(--margin);
    padding: 1em 0em;
    margin: 1.5em auto;
    width: 100%;
}

.player_detail_teams {
    margin: 1.5em 0em;
    width: 80%;
}

.player_detail_nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.player_detail_nav button {
    margin: 0.2em 1em;
    padding: 5px;
    border: solid black 3px;
    border-radius: 7px;
    font-size: 16px;
    font-weight: bold;
    background-color: var(--primary);
}

.fotoPlayer {
    height: 30vh;
    width: 30vh;
    margin: 1em auto;
    border-radius: 10px;
    box-shadow: 0 1px 5px 0 var(--text), 0 1px 3px 0 rgba(0, 0, 0, 0.1);
}

.player_detail_formulario {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.btn_detail_form_input_file::-webkit-file-upload-button {
    align-items: center;
    padding: 0.2em 0.5em;
    margin: 0.5em;
    border: solid black 2px;
    border-radius: 5px;
    font-size: 16px;
    font-weight: bold;
    background-color: black;
    content: 'Elige tu Foto';
    color: var(--dorado);
}

@media (max-width: 600px) {
    .fotoPlayer {
        height: 20vh;
        width: 25vh;
        margin: 1em 0px;
        max-width: 150px;
    }
}