.politica-privacidad {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 6em;
    background-color: white;
    color: black;
    margin: 1em auto;
}
.politica-privacidad h1{
    font-size: 2em;
    font-weight: bold;
}

@media (max-width: 600px) {
    .politica-privacidad {
        padding: 1em;
    }
    .politica-privacidad h1{
        font-size: 1.5em;
    }
}