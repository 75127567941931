
.match_item{
    display: grid;
    grid-template-columns: 1.5fr 1fr 1fr 1fr;
    align-items: center;
    padding: var(--padding-small);
    background: var(--background);
    color: white;
    border: solid black 1px;
    border-radius: 5px;
    padding: var(--padding-small);
    margin: var(--margin) 0px;
    font-size: 16px;
}

.match_item *{
    width: 100%;
    text-align: center;
}
.match_item_team{
    display: grid;
    grid-template-columns: 1fr 2fr;
    align-items: center;
}
.match_item_team_logo{
    height: 6vh;
    width: 6vh;
    margin: auto;
    border-radius: 50%;
}
.match_item_foto{
    height: 5vh;
    width: 5vh;
    margin: auto;
    border-radius: 10%;
    box-shadow: 0 1px 3px 0 var(--text), 0 1px 2px 0 rgba(0, 0, 0, 0.1);
}

@media (max-width: 600px) {
    .match_item {
        border: solid var(--primary) 1px;
        border-radius: 5px;
        background: var(--hover);
    }
    .match_item *{
        width: 100%;
        text-align: center;
    }
    .match_item_team{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .match_team_name {
        font-size: 14px;
        font-weight: bold;
        padding: 0px;
    }
    .match_item_team_logo {
        height: 6vh;
        width: 6vh;
        margin: auto;
        border-radius: 50%;
    }
    .match_item_foto{
        height: 6vh;
        width: 6vh;
        margin: auto;
        border-radius: 10%;
    }
    .match_puntuacion {
        font-size: 16px;
        font-weight: bold;
    }
    .match_fecha {
        font-size: 12px;
    }

}