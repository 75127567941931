body {
    background-image: url(../../public/fondo.png);
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    color: white;
}
main {
    display: flex;
    width: 97%;
    margin: auto;
    margin-bottom: 4em;
}
section {
    width: 80%;
    padding: 0% 15%;
    margin: auto;
}
* ::-webkit-scrollbar {
    display: none;
    /* Ocultar scroll */
}
.list_section {
    display: grid;
    grid-template-columns: 3fr 10fr 3fr;
    grid-template-areas: 
    'asideLeft central asideRight';
    width: 100%;
    gap: 0.5em;
}
.asideLeft {
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-area: asideLeft;
    margin: 1.5em auto;
    width: 100%;
    background: var(--background);
    padding: 1em 0em;
    border-radius: 7px;
}
.asideLeft a{
    display: flex;
}
.central {
    grid-area: central;
}
.asideRight {
    grid-area: asideRight;
    margin: 1.5em auto;
    width: 100%;
}
.list_header_teams {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    background-color: var(--background);
    color: white;
    border: solid var(--primary) 1px;
    border-top-left-radius: 50px 100px;
    border-top-right-radius: 50px 100px;
    text-align: center;
    padding: 0.4em;
    margin-top: 1.5em;
    font-size: 18px;
}
.list_header_matches {
    display: grid;
    grid-template-columns: 1.5fr 1fr 1fr 1fr;
    background-color: var(--background);
    color: white;
    border: solid var(--primary) 1px;
    border-top-left-radius: 50px 100px;
    border-top-right-radius: 50px 100px;
    text-align: center;
    padding: 0.4em;
    margin-top: 1.5em;
    font-size: 18px;
}
.text {
    color: var(--text);
}
.gold {
    color: var(--dorado);
}
.green {
    color: var(--primary);
}
.loading {
    position: absolute;
    width: 100%;
    top: 30%;
    margin: auto;
    z-index: 1000;
}
.permanent {
    font-family: 'Permanent Marker', cursive;
    font-weight: lighter;
}
.btn_section {
    background-color: black;
    margin: 0em 1em;
    padding: 3px 12px;
    border: solid black 3px;
    border-radius: 5px;
    font-size: 16px;
    font-weight: bold;
}
.btn_section:hover {
    cursor: pointer;
    box-shadow: 0 1px 5px 0 var(--text), 0 1px 3px 0 rgba(0, 0, 0, 0.1);
}
.btn_green {
    color: var(--primary);
}
.btn_red {
    color: #E81C00;
}
.btn_gold {
    color: var(--dorado);
}
.border_section {
    border: solid black 1px;
    border-radius: 4px;
}
.border_aside {
    border: solid black 1px;
    border-radius: 2%;
}
.atribuciones{
    font-size: 12px;
    font-weight: lighter;
    text-align: center;
}
ul {
    list-style-type: none;
}
@media (max-width: 600px) {
    body {
        background-image: url(../../public/fondo_mobile.png);
    }
    main {
        width: 95%;
    }
    section {
        width: 100%;
        padding: 0em;
    }
    .list_section {
        grid-template-columns: 1fr;
        grid-template-areas: 
        'central'
        'asideLeft'
        'asideRight';
        gap: 0px;
    }
    .asideLeft {
        display: none;
    }
    .asideRight {
        display: none;
    }
    .list_header {
        border: solid var(--primary) 2px;
        font-size: 16px;
    }
    .list_header_teams {
        font-size: 16px;
    }

    .list_header_matches {
        font-size: 16px;
    }

    .border_section {
        box-shadow: 0 1px 1px var(--background),
            0 6.7px 5.3px rgba(0, 0, 0, .048),
            0 12.5px 10px rgba(0, 0, 0, .06);
        border: solid black 1px;
    }
}