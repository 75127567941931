.contacto {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 6em;
    background-color: white;
    color: black;
    margin: 1em auto;
}
.contacto h1{
    font-size: 2em;
    font-weight: bold;
}
.contacto p,a {
    color: black;
}

@media (max-width: 600px) {
    .contacto {
        padding: 1em;
    }
    .contacto h1{
        font-size: 1.5em;
    }
}