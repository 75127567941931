nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--background);
    grid-area: nav;
    box-shadow: 0 2.8px 2.2px var(--primary),
        0 6.7px 5.3px rgba(0, 0, 0, .048),
        0 12.5px 10px rgba(0, 0, 0, .06);
    z-index: 1;
}
.nav-details {
    width: 100%;
    padding: var(--padding);
}
.nav-superior {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.nav-logo {
    border: solid var(--background) 2px;
    border-radius: 20%;
    width: 3.5vh;
    height: 3.5vh;
    margin: 0em 0.5em;
}    
.nav-logo:hover {
    background-color: var(--primary);
    border: solid var(--dorado) 2px;
}  
.nav-icon {
    background-color: white;
    border: solid var(--primary) 2px;
    border-radius: 50%;
    width: 3.5vh;
    height: 3.5vh;
    margin: 0em 0.5em;
}
.nav-icon:hover {
    background-color: var(--primary);
    border: solid var(--dorado) 2px;
}
.nav-superior-chat {
    visibility: hidden;
}
.nav-central {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0.5em 3em;
}
.nav-details>a:hover {
    color: white;
}
.nav-links {
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: 16px;
}
.nav-links a {
    margin: 0em 0.3em;
    text-decoration: none;
    color: white;
}
.nav-links a:hover,
.nav-links a:focus {
    margin: 0px 0.3em;
    text-decoration: none;
    color: var(--primary);
}
.btn_addTorneo {
    padding: 5px;
    border: solid black 3px;
    border-radius: 7px;
    font-size: 16px;
    font-weight: bold;
    background-color: var(--primary);
    color: black;
}
.btn_addTorneo:hover {
    cursor: pointer;
    box-shadow: 0 1px 5px 0 var(--text), 0 1px 3px 0 rgba(0, 0, 0, 0.1);
}
.google {
    margin: 0em;
}
@media (max-width: 600px) {
    .nav-central {
        margin: 0.3em 0.2em;
        justify-content: space-between;
    }
    .nav-superior{
        margin: 0.1em 0.2em;
    }
    .nav-superior-chat{
        visibility: visible;
    }
    .nav-links {
        font-size: 14px;
    }
    .nav-details {
        padding: var(--padding-small);
        margin: 0em;
    }
    .btn_addTorneo {
        border: solid black 2px;
        border-radius: 5px;
        font-size: 12px;
        padding: 0.4em;
    }
    .google {
        margin: 0.2em 0em 0.2em 0.5em;
    }
}