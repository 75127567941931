.team_item_model{
    display:grid;
    grid-template-columns: 1fr 2fr 1fr;
    align-items: center;
    padding: 0px 0px;
    background: var(--background);
    color: white;
    border: solid black 1px;
    border-radius: 5px;
    margin: var(--margin) 0px;
}
.team_item_model:hover {
    box-shadow: 0 1px 5px 0 var(--text), 0 1px 3px 0 rgba(0, 0, 0, 0.1);
    margin-bottom: 12px;
    border-radius: 7px;
}
.team_item_model_center{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0.2em auto;
}
.team_item_model_logo {
    height: 8vh;
    width: 8vh;
    margin: 0.1em auto;
    border-radius: 50%;
}
.wins{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
.team_item_model_victoria {
    height: 8vh;
    width: 6vh;
    border-radius: 20%;
    margin: 0em 0em;
}
a:link, a:visited, a:active {
    text-decoration:none;
    color: white;
}
.team_item_model h1 {
    font-size: 5em;
    text-align: center;
    font-family:Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
}
.team_item_model h2 {
    font-size: 2.5em;
    text-align: center;
    font-family:Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
}
@media (max-width: 600px) {
    .team_item_model{
        display:grid;
        grid-template-columns: 1fr 2fr 1fr;
        align-items: center;
        background: var(--card);
    }
    .team_item_model h1 {
        font-size: 3em;
    }
    .team_item_model h2 {
        font-size: 2em;
    }
    .team_item_model h3 {
        font-size: 1em;
    }
    .team_item_model_logo {
        height: 6vh;
        width: 6vh;
        margin: auto;
        border-radius: 50%;
    }
    .team_item_model_victoria {
        height: 6vh;
        width: 4vh;
        margin: 0.0em 0.0em;
        border-radius: 20%;
    }
    p {
        font-size: 14px;
    }
}