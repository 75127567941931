.team_detail {
    width: 100%;
}
.team_detail_section{
    display: flex;
    flex-direction: column;
    align-items: center;
    background: var(--background);
    color: white;
    margin-top: var(--margin);
    padding: 1em 0em;
    margin: 1.5em auto;
    width: 100%;
}
.team_detail_nav_copy {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.team_detail_nav_copy_link {
    margin: 0em 1em;
    padding: 0.2em;
    background-color: var(--text);
    border-radius: 7px;
}
.team_detail_teams{
    width: 60%;
}
.copied {
    background-color: var(--primary);
}
.fotoEquipo{
    height: 30vh;
    width: 30vh;
    margin: 1em auto;
    border-radius: 10px;
    box-shadow: 0 1px 5px 0 var(--text), 0 1px 3px 0 rgba(0, 0, 0, 0.1);
}

@media (max-width: 600px) {
    .team_detail_section {
        width: 100%;
        background: var(--hover);
    }
    .fotoEquipo {
        height: 20vh;
        width: 25vh;
        margin: 1em 0px;
        max-width: 150px;
    }
    .team_item_logovictoria {
        width: 20%;
    }
}