.player_item{
    display:grid;
    grid-template-columns:  1fr 2fr;
    align-items: center;
    background: var(--hover);
    color: var(--text);
    border-radius: 12px;
    margin: 0.5em var(--margin) var(--margin) 0em;
    padding: 0em;
}
.player_item:hover {
    box-shadow: 0 1px 5px 0 var(--text), 0 1px 3px 0 rgba(0, 0, 0, 0.1);
}
.player_item_foto {
    height: 5vh;
    width: 5vh;
    margin: 0.3em auto;
    border-radius: 50%;
}
a:link, a:visited, a:active {
    text-decoration:none;
    color: white;
}
.player_item h1 {
    font-size: 5em;
    text-align: center;
    font-family:Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
}
.player_item h3{
    font-size: 1.3em;
}

@media (max-width: 600px) {
    .player_item{
        padding: 1px;
        margin: 0.5em 0em var(--margin) 0em;
        background: var(--card);
    }
    .player_item h1 {
        font-size: 3em;
    }
    .player_item h3 {
        font-size: 1em;
    }
    .player_item img {
        height: 5vh;
        width: 5vh;
        margin: 0.2em 1em;
        border-radius: 50%;
    }
    p {
        font-size: 14px;
    }
}